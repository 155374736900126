import React, { lazy, Suspense, useEffect, useState } from "react";
import { ErrorBoundary } from "ui";
import useUserProfileFacade from "store/Facade";
import { getCategoriesToRender } from "../utils";

const RemoteCategoryRow = lazy(() => import("category_row/CategoryRow"));

const initialRenderData = Array.from({ length: 3 }, () => ({
  categoryName: "",
  displayName: "",
  isCustomCategory: false,
}));

const Feed: React.FC = () => {
  const [renderCategories, setRenderCategories] = useState(initialRenderData);
  const { configData, hasHydrated, isPersonalised } = useUserProfileFacade();

  useEffect(() => {
    if (
      hasHydrated &&
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      configData.selectedTopics &&
      Array.isArray(configData.selectedTopics)
    ) {
      const categories = getCategoriesToRender(
        configData.selectedTopics,
        isPersonalised,
      );
      setRenderCategories(categories);
    }
  }, [configData, hasHydrated, isPersonalised]);

  return (
    <ErrorBoundary fallbackText="An error occurred while loading this part of the page">
      <Suspense>
        {renderCategories.map((element, index) => (
          <RemoteCategoryRow
            categoryTitle={element.categoryName}
            displayName={element.displayName}
            isCustomCategory={element.isCustomCategory}
            key={`${index}${element.categoryName}`}
            slotId={`div-gpt-ad-section-slot-${index + 1}`}
            slotPosition={2}
            styleTheme="publication-theme"
            trackerSrc="pioneer"
          />
        ))}
      </Suspense>
    </ErrorBoundary>
  );
};
export default Feed;
