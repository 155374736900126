import Head from "next/head";
import { lazy, Suspense } from "react";
import { ErrorBoundary } from "ui";
import StyledEngineProvider from "@mui/material/StyledEngineProvider";
import Feed from "@/components/feed/Feed";
import styles from "../styles/Home.module.css";


const RemotePersonalisation = lazy(
  () => import("personalisation/Personalisation"),
);

const RemoteSearch = lazy(() => import("personalisation/Search"));

const Home: React.FC = () => {
  return (
    <>
      <Head>
        <title>My Topics</title>
        <meta content="Generated by create next app" name="description" />
        <meta content="width=device-width, initial-scale=1" name="viewport" />
        <link href="/favicon.ico" rel="icon" />
      </Head>
      <div className={styles.homepage}>
        <ErrorBoundary fallbackText="An error occurred while loading this part of the page">
          <StyledEngineProvider injectFirst>
            <Suspense>
              <div className={styles.personaliseContainer}>
                <RemotePersonalisation styleTheme="publication-theme" />
              </div>
            </Suspense>
          </StyledEngineProvider>
        </ErrorBoundary>
        <ErrorBoundary fallbackText="An error occurred while loading this part of the page">
          <Suspense>
            <RemoteSearch styleTheme="publication-theme" />
          </Suspense>
        </ErrorBoundary>
        <Feed />
      </div>
    </>
  );
};

export default Home;
