import type {
  LocalConfig,
  SavedSearch,
  ProfileCategory,
  SelectedTopic,
} from "ts";

export const getMergedCategories = (
  localConfig: LocalConfig | null,
): Record<string, ProfileCategory | SavedSearch> => {
  const categories = localConfig?.interests;
  const userDefinedCategories = localConfig?.savedSearches;
  return { ...categories, ...userDefinedCategories };
};

export const getCategoriesToRender = (
  selectedTopics: SelectedTopic[],
  isPersonalised: boolean | null,
): SelectedTopic[] => {
  const defaultCategory: SelectedTopic[] = [
    {
      categoryName: "whats-on",
      displayName: "What's On",
      isCustomCategory: false,
    },
  ];
  
  const emptyPersonalisation = isPersonalised && selectedTopics.length === 0;

  const renderCategories = emptyPersonalisation
    ? defaultCategory
    : selectedTopics;

  return renderCategories;
};
